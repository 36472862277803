import PropTypes from "prop-types"
import React from "react"

const ModalContainer = ({ children, customClasses }) => (
  <div
    className={`modal active ${customClasses}`}
    id="myModal"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">{children}</div>
    </div>
  </div>
)

ModalContainer.defaultProps = {
  customClasses: "",
}

ModalContainer.propTypes = {
  children: PropTypes.node.isRequired,
  customClasses: PropTypes.string,
}

export default ModalContainer
