import PropTypes from "prop-types"
import React from "react"

const Icon = ({ type, set, size, param, onClick, className, testId }) => {
  const handleClick = () => {
    if (onClick) {
      // TODO: Find all instances of this and remove it. Passing in a param
      // like this is super gross. Instead just do: `onClick={() => handleOnClick(param)}`
      onClick(param)
    }
  }

  return (
    <i
      onClick={handleClick}
      aria-hidden="true"
      // TODO: Remove `icon` className. Currently doesn't do anything except fix
      // broken styles caused by FontAwesome5 upgrade (since not all fonts use
      // the .fa class anymore...)
      className={`icon ${set} fa-${type} fa-${size} ${className}`}
      data-test-id={testId}
    />
  )
}

Icon.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired,
  param: PropTypes.object,
  size: PropTypes.string,
  set: PropTypes.oneOf(["fa", "fab", "far", "fas"]),
  className: PropTypes.string,
  testId: PropTypes.string,
}

Icon.defaultProps = {
  set: "fa",
  className: "",
  testId: "",
}

export default Icon
